import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Section4 = () => (
  <section id="methode" className="bg-yellow h-full py-32">
    <div
      className="hidden md:flex"
      // style={{ paddingLeft: "10%", paddingRight: "10%" }}
    >
      <div className="w-1/12"></div>
      <div className="w-10/12">
        <StaticImage
          src="../../images/etapes-full.png"
          alt="Meuble dans l'atelier"
          placeholder="blurred"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
    <div className="flex md:hidden">
      <StaticImage
        src="../../images/etapes-mobile.png"
        alt="Meuble dans l'atelier"
        placeholder="blurred"
        style={{ objectFit: "contain" }}
      />
    </div>
  </section>
)

export default Section4
