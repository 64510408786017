import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Section6 = () => (
  <section className="bg-black text-white py-10 px-10 lg:py-32">
    <div className="flex flex-wrap">
      <div className="lg:w-1/12"></div>
      <div className="lg:w-3/12">
        <h1 className="mb-1">Mathieu Coughlin</h1>
        <div className="mb-6">Président - Fondateur</div>
        <div className="section6-content">
          <div className="mb-6 md:hidden">
            <StaticImage
              src="../../images/Mathieu.png"
              alt="TBD"
              placeholder="blurred"
            />
          </div>
          <div className="mb-6">
            J’ai créé élément numéro six pour offrir du mobilier simple,
            durable, écologique, dessiné pour vous et adapté à votre domicile.
            Les meubles fabriqués par élément numéro six sont l’antithèse du
            fast fashion; look sobre et intemporel et d’une durabilité sans
            compromis. Je crois fortement que ‘’less is more’’ et qu’on se doit
            de réduire notre consommation. Le bois est mon medium de
            prédilection, une richesse renouvelable qui nous entoure ici au
            Québec.
          </div>
          <div className="mb-6">
            Je me distingue par ma créativité, ma passion des technologies et de
            l’optimisation.
          </div>
          <div className="mb-6">
            Changer le statu quo est ma quête quotidienne.
          </div>
          <h1 className="pt-10 mb-6">Son expertise</h1>
          <ul>
            <li>Design industriel</li>
            <li>Conception et dessins techniques</li>
            <li>Modélisation 3d et rendus photoréalistes</li>
            <li>Fabrication digitale</li>
            <li>Usinage conventionnelle</li>
          </ul>
        </div>
      </div>
      <div className="hidden md:flex md:w-7/12">
        <StaticImage
          src="../../images/Mathieu.png"
          alt="TBD"
          placeholder="blurred"
        />
      </div>
      <div className="lg:w-1/12"></div>
    </div>
  </section>
)

export default Section6
