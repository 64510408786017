import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Section2 = () => (
  <section id="force" className="bg-orange h-full py-5">
    <div className="py-4 lg:py-32 relative">
      <div className="flex flex-wrap">
        <div className="lg:w-7/12 sm:w-full">
          <StaticImage
            src="../../images/section2-grid.png"
            alt=""
            placeholder="blurred"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="lg:w-1/12"></div>
        <div className="lg:w-3/12">
          <div className="flex flex-col h-full align-middle justify-center px-8 pt-10 lg:px-5 lg:pt-0">
            <h1 className="section2-titre">Notre force</h1>
            <br />
            <div className="section2-text">
              <p>
                Tous nos meubles sont désignés et fabriqués au Québec, de
                première qualité, avec des matériaux écologiques et durables.
              </p>
              <br />
              <p>
                Notre ambition est de maximiser votre espace pour créer du
                mobilier intégré pour des cuisines, salles de bains et
                rangements haut de gamme.
              </p>
              <br />
              <p>
                Toute notre réflexion est faite en fonction des restrictions
                d’espace.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Section2
