import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const links = [
  {
    route: "https://www.behance.net/elementnumerosix",
    title: "Behance",
  },
  {
    route: "https://www.instagram.com/elementnumerosix/",
    title: "Instagram",
  },
]

const Section1 = () => (
  <section id="home" className="bg-black">
    <div className="absolute text-white section1-text">
      <p>
        On vous offre un écosystème de meubles haut de gamme ajustés, durables
        et écologiques
      </p>
    </div>

    <div className="container mx-auto py-10">
      <StaticImage
        src="../../images/index-img.jpeg"
        alt="Mathieu travaille sur un meuble"
        objectFit="contain"
        placeholder="blurred"
        className="w-full"
        style={{ objectFit: "contain", maxHeight: "75vh" }}
      />
    </div>

    <div className="flex flex-wrap items-center justify-between p-6 bg-black">
      <div className="text-sm">
        {links.map(link => (
          <Link
            to={`${link.route}`}
            className="leading-none block mt-4 mr-14 text-light-grey inline-block lg:mt-0 hover:text-white hvr-overline-from-center "
          >
            {link.title}
          </Link>
        ))}
      </div>
    </div>
  </section>
)

export default Section1
