import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Section2 = () => (
  <section id="methode" className="bg-yellow h-full py-5">
    <div className="py-4 lg:py-32 relative">
      <div className="flex flex-wrap">
        <div className="lg:w-1/12"></div>
        <div className="lg:w-3/12 sm:w-full order-9 lg:order-none">
          <div className="flex flex-col h-full align-middle justify-center px-8 pt-10 lg:px-5 lg:pt-0">
            <h1 className="section3-titre">Méthode</h1>
            <br />
            <div className="section3-text">
              <p>
                Des meubles sur mesure; automatisés, à la main. Un processus
                artisanal avec la précision numérique d'une CNC.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/12"></div>
        <div className="lg:w-7/12 sm:w-full">
          <StaticImage
            src="../../images/section3-grid.png"
            alt="Meuble dans l'atelier"
            placeholder="blurred"
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  </section>
)

export default Section2
