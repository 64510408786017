import React from "react"

import Section1 from "../components/sections/section1"
import Section2 from "../components/sections/section2"
import Section3 from "../components/sections/section3"
import Section4 from "../components/sections/section4"
import Section5 from "../components/sections/section5"
import Section6 from "../components/sections/section6"
import Section7 from "../components/sections/section7"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Élément Numéro Six" />

    <Section1 />

    <Section2 />

    <Section3 />

    <Section4 />

    <Section5 />

    <Section6 />

    <Section7 />
  </Layout>
)

export default IndexPage
