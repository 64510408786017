import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios"

const Section7 = () => {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [error, setError] = React.useState("")
  const [success, setSucess] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const submit = async () => {
    setLoading(true)

    try {
      if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        setError("Courriel invalide")
        return
      }

      await axios.post(".netlify/functions/send-contact-email", {
        contactName: name,
        contactEmail: email,
        message,
      })
      setSucess(true)
    } catch (e) {
      console.log(e)
      setError(e)
    }

    setLoading(false)
  }

  return (
    <section id="contact" className="bg-orange py-10 lg:py-32 px-10 lg:px-0">
      <div className="flex flex-wrap">
        <div className="lg:w-2/12"></div>
        <div className="lg:w-2/12 mb-10 lg:mb-32">
          <div>
            <div className="section7-titre">Contact</div>
            <br />
            <p className="section7-text">Parlez-nous de votre projet!</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="lg:w-2/12"></div>

        <div className="w-full lg:w-3/12">
          <div className="relative z-0 mb-6 lg:w-4/5 group">
            <input
              type="name"
              name="name"
              id="name"
              className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-black appearance-none dark:text-light-grey dark:border-black dark:focus:leight-grey focus:outline-none focus:ring-0 focus:border-light-grey peer"
              placeholder=" "
              required
              onChange={e => setName(e.target.value)}
            />
            <label className="peer-focus:font-medium absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-light-grey peer-focus:dark:text-light-grey peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
              Nom
            </label>
          </div>
          <div className="relative z-0 mb-6 lg:w-4/5 group">
            <input
              type="email"
              name="email"
              id="email"
              className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-black appearance-none dark:text-light-grey dark:border-black dark:focus:leight-grey focus:outline-none focus:ring-0 focus:border-light-grey peer"
              placeholder=" "
              required
              onChange={e => setEmail(e.target.value)}
            />
            <label className="peer-focus:font-medium absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-light-grey peer-focus:dark:text-light-grey peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
              Courriel
            </label>
          </div>
          <div className="relative z-0 mb-6 lg:w-4/5 group pt-10 lg:pt-20">
            {!success && (
              <button
                className="bg-black hover:bg-light-grey text-white py-2 px-4 rounded-full"
                style={{ fontSize: "0.8em" }}
                onClick={submit}
              >
                Soumettre
              </button>
            )}
            {success && (
              <div>
                <p>Votre message a été envoyé avec succès!</p>
              </div>
            )}
            {error.length > 0 && (
              <div>
                <p>Une erreur est survenue lors de l'envoie du message:</p>
                <p>{error}</p>
              </div>
            )}
          </div>
        </div>
        <div className="lg:w-1/12"></div>
        <div className="w-full lg:w-4/12 pt-5 mb-14 lg:mb-0 -order-1 lg:order-last">
          <textarea
            id="message"
            rows={10}
            className="block p-2.5 w-full text-sm text-black rounded-lg border border-black focus:ring-light-grey focus:border-light-grey bg-orange placeholder-black"
            placeholder="Message"
            style={{ resize: "none" }}
            onChange={e => setMessage(e.target.value)}
          ></textarea>
        </div>
      </div>
    </section>
  )
}

export default Section7
