import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Section5 = () => (
  <section className="bg-white pt-10 lg:pt-32">
    <div className="flex">
      <div className="w-1/12"></div>
      <div className="w-10/12">
        <StaticImage
          src="../../images/section2.png"
          alt="TBD"
          placeholder="blurred"
          style={{
            objectFit: "contain",
          }}
          className="mx-auto"
        />
        <div
          id="philosphie"
          className="flex flex-wrap"
          style={{ marginTop: "10%" }}
        >
          <div className="lg:w-1/2 sm:w-full">
            <div className="lg:w-4/5">
              <div className="lg:w-3/4" style={{ marginBottom: "20%" }}>
                <h1 className="section5-titre">
                  <span>Un processus</span>
                  <br />
                  <span>éco-responsable</span>
                </h1>
                <br />
                <br />
                <div className="section5-text">
                  <p>Réduction des pertes de matériaux</p>
                  <p>Emballage à plat pour optimisation du transport</p>
                  <p>Emballage éco-responsable simplifié</p>
                  <p>Meubles sans formaldéhyde</p>
                  <p>Produits de finition à base d'eau</p>
                </div>
              </div>
              <StaticImage
                src="../../images/section5a.png"
                alt="Meuble dans l'atelier"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="lg:w-1/2 my-10 lg:my-0">
            <StaticImage
              src="../../images/section5c.png"
              alt=""
              placeholder="blurred"
              className="mb-10"
            />
            <StaticImage
              src="../../images/section5b.png"
              alt=""
              placeholder="blurred"
              className="lg:my-32"
            />
          </div>
        </div>
      </div>
      <div className="w-1/12"></div>
    </div>
  </section>
)

export default Section5
